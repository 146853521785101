
import { Options, Vue } from "vue-class-component";
import TopNav from "@/components/TopNav.vue";
import Explainer from "@/components/explainer.vue";
import WhoAmI from "@/components/WhoAmI.vue";
import ExtraTreatments from "@/components/ExtraTreatments.vue";
import Footer from "@/components/footer.vue";

@Options({
  components: {
    TopNav,
    Explainer,
    WhoAmI,
    ExtraTreatments,
    Footer,
  },
})
export default class HomeView extends Vue {}
