
import { Options, Vue } from "vue-class-component";
import { BIconList } from "bootstrap-icons-vue";

@Options({
  // props: {
  //   collapsed: Boolean,
  // },
  components: { BIconList },
})
export default class TopNav extends Vue {
  collapsed = true;

  scrollTo(selector: string) {
    document.querySelector(selector)?.scrollIntoView();
    this.collapsed = true;
  }
}
