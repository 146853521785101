import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNav = _resolveComponent("TopNav")!
  const _component_Explainer = _resolveComponent("Explainer")!
  const _component_WhoAmI = _resolveComponent("WhoAmI")!
  const _component_ExtraTreatments = _resolveComponent("ExtraTreatments")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopNav),
    _createVNode(_component_Explainer),
    _createVNode(_component_WhoAmI),
    _createVNode(_component_ExtraTreatments),
    _createVNode(_component_Footer)
  ]))
}